@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Avenir:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

@font-face {
  font-family: 'FuturaLight';
  src: url('./assets/futura/FUTURA45LIGHT.TTF');
}

@font-face {
  font-family: 'FuturaRegular';
  src: url('./assets/futura/FUTURA55REGULAR.TTF');
}

@font-face {
  font-family: 'FuturaMedium';
  src: url('./assets/futura/FUTURA65MEDIUM.TTF');
}

@font-face {
  font-family: 'FuturaBold';
  src: url('./assets/futura/FUTURA75BOLD.TTF');
}

* {
  margin: 0px;
  padding: 0px;
  font-family: 'Nunito Sans', 'Futura', 'Inter';
}

::-webkit-scrollbar {
  width: 0;
}
